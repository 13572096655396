<template>
  <el-row class="templater-app">
    <el-col :span="8">
      <page-nav class="grid-content" />
    </el-col>
    <el-col :span="16">
      <pdf-viewer
        v-if="hasUrl"
        v-loading="previewIsLoading"
        v-bind="{ url }"
        @document-errored="onDocumentErrored"
      />
      <div v-else class="no-url">
        <p>{{ instructions }}</p>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PdfViewer from '../components/pdf/PDFViewer.vue'
import PageNav from './nav.vue'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.utilities.document_designer')
    }
  },
  components: {
    PageNav,
    PdfViewer
  },
  data() {
    return {
      documentError: undefined,
      enableUploader: false
    }
  },
  computed: {
    instructions() {
      if (!this.templateId) {
        return "There is no template for full receipts yet.\nClick 'Save' to set up an initial template in the database."
      }
      return "Click 'Preview' to\nshow an example PDF"
    },
    ...mapState({
      url(state) {
        return state.Templates.previewUrl
      },
      hasUrl(state) {
        return !!state.Templates.previewUrl
      },
      templateId(state) {
        return (
          state.Templates.currentTemplate && state.Templates.currentTemplate.id
        )
      }
    }),
    ...mapGetters({
      previewIsLoading: 'Templates/previewIsLoading'
    })
  },
  created() {
    this.$store.dispatch(
      'Templates/setCurrentTemplate',
      this.$store.getters['Templates/getFullReceipts'][0]
    )
    if (this.templateId) {
      this.$store.dispatch('Templates/preview', {
        payload: this.$store.getters['Templates/getFullReceipts'][0],
        vm: this
      })
    }
  },
  methods: {
    urlUpdated(url) {
      this.documentError = undefined
      this.url = url
    },
    onDocumentErrored(e) {
      this.documentError = e.text
    }
  }
}
</script>

<style scoped>
.templater-app,
.templater-app > * {
  height: 100%;
}

.templater-app {
  overflow-y: hidden;
}

.no-url {
  background: #c4c4c4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  user-select: none;
}

.no-url p {
  white-space: pre-line;
  text-align: center;
}
</style>
