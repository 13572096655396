<template>
  <div class="mb-5">
    <th-input-title
      for="amountReceived"
      :title="$t('pages.document_designer.amountReceived.title')"
    />
    <el-input
      id="amountReceived"
      v-model="value"
      :placeholder="$t('pages.document_designer.amountReceived.title')"
      maxlength="128"
    />
    <div v-if="invalid" class="warning">
      {{ $t('pages.document_designer.amountReceived.warning') }}
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options.amount_received ||
          ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          amount_received: value
        })
      }
    },
    invalid() {
      return this.value.length > 128
    }
  }
}
</script>

<style scoped>
.warning {
  color: red;
  font-size: 12px;
  float: right;
  margin-top: 2px;
  margin-right: 3px;
}
</style>
