<template>
  <div class="mb-5">
    <th-input-title
      for="locationAndDate"
      :title="$t('pages.document_designer.locationAndDate.title')"
    />
    <el-input
      id="locationAndDate"
      v-model="value"
      :placeholder="$t('pages.document_designer.locationAndDate.placeholder')"
      maxlength="32"
    />
  </div>
</template>

<script>
export default {
  computed: {
    value: {
      get() {
        return (
          this.$store.state.Templates.currentTemplate.options
            .location_and_date || ''
        )
      },
      set(value) {
        this.$store.commit('Templates/setCurrentTemplateOptionsProp', {
          location_and_date: value
        })
      }
    }
  }
}
</script>
