<template>
  <section>
    <control-nav @view-change="handleViewChange" />
    <div class="content">
      <pdf-general v-show="view === 'general'" />
      <pdf-headers v-show="view === 'headers'">
        <template #additionals>
          <location-and-date />
          <signature />
          <amount-received />
        </template>
      </pdf-headers>
      <pdf-footer v-show="view === 'footer'" />
      <pdf-logo v-show="view === 'logo'" />
    </div>
  </section>
</template>

<script>
import ControlNav from '../../components/control/nav.vue'
import PdfGeneral from './general.vue'
import PdfHeaders from '../../components/control/headers.vue'
import PdfFooter from '../../components/control/footer.vue'
import PdfLogo from '../../components/control/logo.vue'
import LocationAndDate from '../../components/control/components/location-and-date.vue'
import Signature from '../../components/control/components/signature.vue'
import AmountReceived from '../../components/control/components/amount-received.vue'

export default {
  components: {
    PdfGeneral,
    PdfHeaders,
    PdfFooter,
    PdfLogo,
    ControlNav,
    LocationAndDate,
    Signature,
    AmountReceived
  },
  data() {
    return {
      view: 'general'
    }
  },
  methods: {
    handleViewChange(view) {
      this.view = view
    }
  }
}
</script>

<style scoped>
section {
  height: 100%;
  display: flex;
}

.content {
  padding: 0 2em 4rem 2rem;
  width: 100%;
  overflow-y: scroll;
}
</style>
