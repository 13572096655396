<template>
  <section>
    <control />
  </section>
</template>

<script>
import Control from './control'
export default {
  components: {
    Control
  }
}
</script>

<style scoped>
section {
  height: 100%;
}
</style>
